<template>
  <div>
    <b-card borderless v-if="joke">
      <div class="table-responsive">
        <table class="table table-borderless">
          <thead class="thead-success">
            <tr class="font-weight-bolder-header">
              <th scope="col" class="text-left"><h5></h5></th>
              <th scope="col" class="text-left"><h5>Return on Capital Employed</h5></th>
              <th scope="col" class="text-left"><h5>Debt to Equity Ratio</h5></th>
              <th scope="col" class="text-left"><h5>Free Cashflow Growth</h5></th>
              <th scope="col" class="text-left"><h5>PEG-Ratio</h5></th>
              <th scope="col" class="text-left"><h5></h5></th>
              <th scope="col" class="text-left"><h5></h5></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item) in joke" :key="item.id" class="data-row">
              <td class="text-left power-effect" @click="handleCompanyClick(item.Name)">
                <span class="data-label">Name: </span>{{ item.Name }}
              </td>
              <td class="text-left">
                <span class="data-label">Return on Capital Employed: </span>{{ item['ROIC']+'%' }}
              </td>
              <td class="text-left">
                <span class="data-label">Debt to Equity Ratio: </span>{{ item['debt2Equity']+'%' }}
              </td>
              <td class="text-left">
                <span class="data-label">Free Cashflow Growth: </span>{{ item['FCF-Growth']+'%' }}
              </td>
              <td class="text-left">
                <span class="data-label">PEG-Ratio: </span>{{ item['PEG-Ratio'] }}
              </td>
              <td class="text-left actions-cell">
                <b-button
                  variant="primary"
                  @click="addToWatch(item.Name, item.Price), showToast(item.Name)"
                  v-b-popover.hover="'By clicking this Button you can add this specific company to your Watchlist for further research'"
                  class="mr-1"
                >
                  <feather-icon icon="PlusIcon" class="mr-40 align-middle" />
                  <span class="align-middle">Watchlist</span>
                </b-button>
                <b-button
                  variant="success"
                  @click="addToPortfolio(item.Name), PortfolioToast(item.Name)"
                  v-b-popover.hover="'By clicking this Button you can add this specific company directly to your Portfolio'"
                >
                  <feather-icon icon="PlusIcon" class="mr-40" />
                  <span class="align-middle">Portfolio</span>
                </b-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BAvatar, BImg,
} from 'bootstrap-vue'
import { methods } from 'vue-echarts';
import Ripple from 'vue-ripple-directive'
import { BButton, VBPopover, BPopover } from 'bootstrap-vue'
import { EventBus } from '@/main';
import { getAuth } from "firebase/auth";
import { doc, setDoc, updateDoc } from "firebase/firestore"; 
import { db } from '@/firebase/index'
import { getItemStyle } from 'echarts/lib/model/mixin/itemStyle';
import { collection, query, where, getDocs, arrayUnion, getDoc } from "firebase/firestore";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BCard,
    BTable,
    BAvatar,
    BImg,
    VBPopover, 
    BPopover,
  },
  computed: {
    joke() {
      return this.$store.getters.getBuffettScreen
    },
    // companyTable() { 
    //   this.queryScreener().then((value) => {
    //   const companyTable = value
    //    console.log(companyTable)
    //    return this.companyTable
    //   });
    // } 
    
  },
 created(){
    this.companyTable =  this.queryScreener()
    EventBus.$on('suggestionSelected',(data) => {
        this.compName= data.name
    });

  },
  data() {
    return {  
    }
  },
  directives: {
    Ripple,
    
  },
  methods: {
    async handleCompanyClick(compName) {
      const docSnap = await getDoc(doc(db, 'companies', compName))

      if (docSnap.exists()) {
        // assign document fields
        // to data properties
        const data = docSnap.data()
        EventBus.$emit('getCompany',data)
        this.$store.dispatch('setCurrentCompdata',data)
        this.$router.push('/companyanalysis')
      } else {
        console.log('Document does not exist')
      }
    },
    nFormatter(num) {
     if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
     }
     if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
     }
     if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
     }
     return num;
    },
    async addToWatch(x, LastPrice)  {
      const auth = getAuth();
      const user = auth.currentUser.uid;
      await setDoc(doc(db,"users",user),({ Watchlist: {[x]: LastPrice}}),{ merge: true })
    },
    async addToPortfolio(x)  {
      const auth = getAuth();
      const user = auth.currentUser.uid;
      const docSnap = await getDoc(doc(db, 'companies', x))
        if (docSnap.exists()) {
          // assign document fields
          // to data properties
          const data = docSnap.data()
          const Price = data.LastPrice
          await updateDoc(doc(db,"users",user),({ Portfolio: arrayUnion({Name: x, Quantity:1, BuyPrice: Price})}),{ merge: true })
        }

    },
    async queryScreener() {
      const table = []
      const q = query(collection(db, "companies"), where("BuffettScreener", "==", true));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        const ROIC = doc.data().ROIC
        const PEG_Ratio = doc.data()["PEG-Ratio"]
        const Fcf_growth = doc.data().FcfGrowth
        const debt2Equity = doc.data().debt2Equity
        const LastPrice = doc.data().LastPrice
        const data = {"Name":doc.id, "Price":LastPrice, "ROIC": ROIC, "PEG-Ratio": PEG_Ratio,  "FCF-Growth": Object.values(Fcf_growth).pop(), "debt2Equity": debt2Equity}
        table.push(data)
        console.log(data)
        this.$store.dispatch('setBuffettScreen',table)
      });
      this.$store.dispatch('setBuffettScreen',table)
    },
    showToast(Name) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Watchlist',
          icon:"HeartIcon",
          text: `${Name} was added`,
          variant:"primary",
        },
      })
    },
    PortfolioToast(Name) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Portfolio',
          icon:"PieChartIcon",
          text: `${Name} was added`,
          variant:"success",
        },
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.thead-success th {
  background-color: #d4edda;
  text-align: left;
  padding: 8px;
}

.font-weight-bolder-header {
  font-weight: bold;
}

.text-left {
  text-align: left;
}

.table-responsive {
  overflow-x: auto;
}

.table-borderless {
  border: none;
}

.data-label {
  display: none;
  font-weight: bold;
}

.actions-cell .b-button {
  margin-right: 10px;
}

.power-effect {
  position: relative;
  text-align: center;
  font-size: 15px;
  padding: 20px;
  color: primary;
  transition: transform 0.2s, box-shadow 0.2s;
}

.power-effect:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) {
  .thead-success {
    display: none;
  }

  .data-row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 0;
  }

  .data-row td {
    width: 100%;
    padding: 5px 0;
    border-bottom: 1px solid #ddd;
  }

  .data-label {
    display: inline-block;
    width: 30%;
    padding-right: 10px;
  }

  .data-row td:last-child {
    border-bottom: none;
  }

  .b-button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .b-button {
    margin-top: 5px;
  }
}
</style>
