<template>
  <div>
    <section id="card-text-alignment">
      <b-row class="">
        <!-- left align -->
        <b-col
          md="6"
          lg="12"
        >
          <b-card 
            title="How to find the companies you're looking for"
            class="mb-0 mt-20"
          >
            <b-card-text class="mt-20">
              You might be familiar with the Terms: Profit-Margin, Free-Chashflow-Margin, Return on net-tangible Assets . If you do not know them yet, you can click below to learn what these terms mean and why the are the most relevant to measure profitability of a specific Company.
            </b-card-text>
            
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-modal.modal-center
              variant="outline-primary"
            >
              Explain it to me
            </b-button>
            <b-modal
              id="modal-center"
              centered
              title="Vertically Centered"
              ok-only
              ok-title="Accept"
            >
              <b-card-text>
                1.Return on Capital Employed (ROCE):
                  Think of ROCE like a report card for a company's efficiency. It shows how well the company is using its money to make even more money. High ROCE means the company is doing a good job at this.
                2.Debt to Equity Ratio:
                  Imagine you and your friends are starting a lemonade stand. If you use a lot of your own money (equity) and very little borrowed money (debt), it's safer. The Debt to Equity Ratio measures how much of the lemonade stand is funded by loans. Lower is often safer.
                3.PEG Ratio (Price/Earnings to Growth Ratio):
                  This is like judging a race car not just by its speed (P/E ratio) but also by how much faster it might get in the future (earnings growth). A PEG ratio below 1 means you're getting more speed for your money.
                4.Free Cash Flow Growth:
                  Think of this as your allowance. If you're getting more money each week (growing free cash flow), that's good. It means the company has extra cash to do cool things like buy new toys (invest in growth), share with friends (dividends), or save for later.
              </b-card-text>
            </b-modal>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </div>
  
</template>

<script>
import {  doc, getDoc } from "firebase/firestore"
import { onBeforeMount } from "vue-demi"
import  {db}  from '@/firebase/index.js'
 import {
  BRow, BCol, BCard, BCardText, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { BModal, VBModal, BAlert} from 'bootstrap-vue'



export default {

  components: {
    BRow, BCol, BCard, BCardText, BButton,BModal,VBModal,BAlert
  },
  directives: {
    Ripple,'b-modal': VBModal,
  
  },
}
</script>
