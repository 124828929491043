<template>
  <b-card>
      <div>
        <b-card-text>
          <h3>Buffett Criterias</h3>
        </b-card-text>
          <div class="row">
            <div class="col-md-6">
              <label  class="mr-1 ml-1 text-muted"
                for="demo-sb">Return on Capital Employed :</label>
                  <b-form-spinbutton
                  disabled
                    class="mb-5"
                    id="demo-sb"
                    v-model="ROIC"
                    min="1"
                    max="100"/>
            </div>
            <div class="col-md-6">
              <label  class="mr-1 ml-1 text-muted"
                for="demo-sb">Debt to Equity :</label>
                  <b-form-spinbutton
                  disabled
                    id="demo-sb"
                    v-model="d2e"
                    min="-2"
                    max="100"
                    step="0.5"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <label  class="mr-1 ml-1 text-muted"
                for="demo-sb"> Free Cashflow Growth :</label>
                  <b-form-spinbutton
                  disabled
                    id="demo-sb"
                    v-model="ROIC"
                    min="1"
                    max="100"/>
            </div>
            <div class="col-md-6 mb-4">
              <label  class="mr-1 ml-1 text-muted"
                for="demo-sb">PEG-Ratio:</label>
                  <b-form-spinbutton
                  disabled
                    id="demo-sb"
                    v-model="PEGRatio"
                    min="1"
                    max="100"/>
            </div>
          </div>
        <b-row class="mt-4">
              <p class="card-text"><small class="text-muted">These Values are inspired by Berkshire-Hathaways official Acquistition criteria provided by Warren Buffett and Charlie Munger. Keep in mind that this Screener <h6>DOES NOT REPLACE INVESTIAGTING IN YOUR OWN RESEARCH</h6></small></p>
      </b-row>
      </div>
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { BFormSpinbutton, BAlert, BCardText } from 'bootstrap-vue'
import { codeBasic } from './code'

export default {
  components: {
    BFormSpinbutton,
    BCardCode,
    BCardText,
    BAlert,
  },
  data() {
    return {
      value: 50,
      ROIC: 10,
      d2e: 0.5,
      FCFGrowth: 10,
      margin: 10,
      codeBasic,
      PEGRatio: 1,
    }
  },
}
</script>

<style>
.b-form-spinbutton {
  border-radius: 15px; /* Set the border radius to 15px */
}
</style>